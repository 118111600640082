<template>
    <Layout :navigation="navigationObj">
        <router-view/>
    </Layout>
</template>

<script>
import Layout from "../components/Layout";

export default {
        name: "Users",
        data() {
            return {
                navigationObj: {
                    items: [
                        {
                            title: 'All Users',
                            name: 'all-users',
                            routeName: 'all-users',
                            isSection: true
                        },
                        {
                            title: 'Add new users',
                            name: 'create-user',
                            routeName: 'create-user'
                        },
                        {
                            title: 'All Organizations',
                            name: 'all-organizations',
                            routeName: 'all-organizations',
                            isSection: true
                        },
                        {
                            title: 'Create new organization',
                            name: 'create-organization',
                            routeName: 'create-organization'
                        }
                    ]
                }
            }
        },
        components: {
            Layout
        }
    }
</script>

<style lang="scss" scoped>

</style>
